<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3>Dirección</h3>
      </v-col>
      <v-col cols="12" sm="6">
        <TextField
          id="mapsAutocompleteField"
          v-model="mapsAutocompleteField"
          prepend-icon="mdi-google-maps"
          ref="origin"
        />
      </v-col>
      <v-col cols="12" sm="6" class="p-0" style="height: 20rem; background-color: red">
        <GmapMap
          ref="addressMap"
          style="height: 100%; width: 100%"
          :center="{ lat: 19.432398773592222, lng: -99.13321643042559 }"
          :zoom="12"
        ></GmapMap>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="address.street"
          label="Calle *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="address.outer_number"
          label="Número Exterior *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="address.inner_number"
          label="Número Interior"
          prepend-icon="mdi-google-maps"
          rules=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="address.postal_code"
          counter="5"
          label="Código Postal *"
          maxlength="5"
          prepend-icon="mdi-google-maps"
          rules="required|min:5|max:5"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="address.suburb"
          :items="postalCodes"
          item-text="suburb"
          item-value="suburb"
          label="Colonia *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="address.municipality"
          :items="postalCodes"
          item-text="municipality"
          item-value="municipality"
          label="Municipio/Alcaldía *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="address.city"
          :items="postalCodes"
          item-text="city"
          item-value="city"
          label="Ciudad *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="address.state"
          :items="postalCodes"
          item-text="state"
          item-value="state"
          label="Estado *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="address.country"
          :items="countries"
          item-text="name"
          item-value="id"
          label="País *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// API
import CoreAPI from '@/api/core';

export default {
  name: 'AddressForm',
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    mapsAutocompleteField: '',
    countries: [{ id: 'MX', name: 'México' }],
    postalCodes: [],
  }),
  computed: {
    postalCode() {
      return this.address?.postal_code;
    },
  },
  watch: {
    postalCode: 'getPostalCodeInfo',
  },
  async created() {
    this.coreAPI = new CoreAPI();
    await this.getPostalCodeInfo();
    this.$refs.addressMap.$mapPromise.then((map) => {
      map.panTo({ lat: 19.432398773592222, lng: -99.13321643042559 });
      const input = this.$el.querySelector('input#mapsAutocompleteField');
      //           label="Ingresa Tu Dirección"
      const options = {
        componentRestrictions: { country: 'mx' },
        types: ['address'],
        strictBounds: false,
      };
      const autocomplete = new window.google.maps.places.Autocomplete(input, options);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        // console.log(place);
        let i;
        let j;
        for (i = 0; i < place.address_components.length; i += 1) {
          for (j = 0; j < place.address_components[i].types.length; j += 1) {
            if (place.address_components[i].types[j] === 'postal_code') {
              this.address.postal_code = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] === 'street_number') {
              this.address.outer_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] === 'route') {
              this.address.street = place.address_components[i].long_name;
            }
          }
        }
      });
    });
  },
  methods: {
    async getPostalCodeInfo() {
      if (!this.postalCode || this.postalCode.length !== 5) return;
      this.$store.commit('showLoader');
      try {
        this.postalCodes = await this.coreAPI.postalCodes({
          postal_code: this.postalCode,
        });
        console.log(this.postalCodes);
        if (this.postalCodes.length > 0) {
          this.address.city = this.postalCodes[0].city;
          this.address.municipality = this.postalCodes[0].municipality;
          this.address.suburb = this.postalCodes[0].suburb;
          this.address.state = this.postalCodes[0].state;
          this.address.country = this.postalCodes[0].country;
        } else {
          this.address.city = null;
          this.address.municipality = null;
          this.address.suburb = null;
          this.address.state = null;
          this.address.country = null;
        }
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      } finally {
        this.$store.commit('hideLoader');
        console.log(this.address.city);
      }
    },
    searchInPostalCodes(attribute, value) {
      const exists = this.postalCodes.some((postalCode) => postalCode[attribute] === value);
      return exists ? value : null;
    },
  },
};
</script>

<style lang="sass" scoped></style>
