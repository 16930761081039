// API
import BaseAPI from './base';

/**
 * Implement Customer API
 */
export default class CustomerAPI extends BaseAPI {
  constructor() {
    super('people');
  }

  /**
   * Attach a new address to a customer.
   * Adjunte una nueva dirección a un cliente.
   */
  address(id, data) {
    return this.request.post(`${this.baseURI}/${id}/address`, data);
  }

  /**
   * Get all office's customer.
   * Obtenga todos los clientes de la oficina.
   */
  list({ params } = {}) {
    return super.list({ ...params, type: 'customer' });
  }

  /**
   * Register a new customer.
   * Registrar una nueva cliente.
   */
  signup(data) {
    return this.request.post(`${this.baseURI}/signup`, data);
  }

  /**
   * Set office for customer.
   * Establecer oficina para la cliente.
   */
  setOffice(id, data) {
    return this.request.post(`${this.baseURI}/${id}/office`, data);
  }

  /**
   * Set all office.
   * Establecer toda la oficina.
   */
  setAllOffice() {
    return this.request.post(`${this.baseURI}/set-all-office`);
  }
}
